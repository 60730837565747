if (document.getElementById("heroSlider")) {
    var swiper = new Swiper("#heroSlider", {

        slidesPerView: 1,
        slidesPerGroup: 1,
        lazy: true,
        loop: false,
        // loopFillGroupWithBlank: true,

        navigation: {
            nextEl: "#heroSlider .swiper-button-next",
            prevEl: "#heroSlider .swiper-button-prev",
        },

    });
}
if (document.getElementById("p-swiper")) {
    var swiper = new Swiper("#p-swiper", {
        spaceBetween: 20,
        slidesPerView: 2,
        slidesPerGroup: 1,
        loop: false,
        // loopFillGroupWithBlank: true,

        navigation: {
            nextEl: "#p-swiper .swiper-button-next",
            prevEl: "#p-swiper .swiper-button-prev",
        },
        breakpoints: {
            640: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 4,
            }
        },
    });
}